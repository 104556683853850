<template>
  <div class="page">
    <section>
      <span class="title">活动内容</span>
      <div class="content">
        <div class="group">
          <span class="groupTitle">活动标题</span>
          <span>{{ detail.title }}</span>
        </div>
        <div class="group">
          <span class="groupTitle">活动时间</span>
          <span>{{ detail.create_time | timeFormat("yyyy-mm-dd") }}</span>
        </div>
        <div class="group">
          <span class="groupTitle">活动奖品</span>
          <span>礼金¥{{ detail.cash_gift }}</span>
        </div>
        <div class="group">
          <span class="groupTitle">活动内容</span>
          <span>{{ detail.present }}</span>
        </div>
      </div>
      <el-button
        class="button"
        :loading="isLogin"
        type="primary"
        @click="add"
        round
        >我要参与</el-button
      >
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: "",
      isLogin: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$researchApi.questionnaireDetail({
        questionnaire_id: this.$route.query.id,
      });
      if (res.code == 200) {
        this.detail = res.data || [];
      }
      this.xin.isLoadding();
    },
    // 参加调研
    async add() {
      this.isLogin = true;
      let res = await this.$researchApi.addQuestionnaire({
        questionnaire_id: this.$route.query.id,
        user_source: 3,
        type: 2,
      });
      this.isLogin = false;
      if (res.code == 200) {
        this.xin.goUrl("/research-interview-successfulParticipation");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      font-size: 18px;
      color: #333333;
      margin: 30px 0 22px 0;
      font-weight: bold;
    }
    > .content {
      display: flex;
      flex-direction: column;
      width: 1200px;
      background: #ffffff;
      margin-bottom: 30px;
      padding: 30px 42px 50px 30px;
      box-sizing: border-box;
      > .group {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        color: #333333;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        > .groupTitle {
          font-size: 18px;
          color: #8f8f8f;
          margin-bottom: 10px;
        }
      }
    }
    > .button {
      width: 384px;
      font-size: 15px;
      margin: 0 auto;
    }
  }
}
</style>